import React, { useEffect,useContext,useState } from "react";
import { useHistory,Link  } from 'react-router-dom';
import { Button,Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import {Helmet} from "react-helmet";
import { CookieBanner } from "@palmabit/react-cookie-law";
import Context from '../../_helpers/context';

import './Lp3.scoped.css'
const lang = require('../../_helpers/lang/lang.json')

export default function Lp3() {

  const context = useContext(Context)
  const locale = context.lang
  const landing = "lp3"


  
  const setupDomande = [
    {
      titolone:lang[locale][landing].titolo1, 
      domanda:lang[locale][landing].domanda1,
      pulsanti:1,
      tipologia:"messaggio",
    },
    {
        domanda:lang[locale][landing].domanda2,
        pulsanti:1,
        tipologia:"messaggio",
      },
      {
        nome_domanda:lang[locale][landing].nome_domanda1,
        domanda:lang[locale][landing].domanda3,
        sottodomanda:lang[locale][landing].sottodomanda1,
        tipologia:"domanda_checkbox",
        img: require("./img/10.png").default,
        selezioni:[
            "Whatsapp","Snapchat","Viber","Facebook",lang[locale][landing].selezione1
        ],
        pulsanti:1,
      },
      {
        nome_domanda:lang[locale][landing].nome_domanda1,
        tipologia:"domandaDuePulsanti",
        img: require("./img/20.png").default,
        testo:lang[locale][landing].testo1,
        pulsanti:2,
      },
      {
        nome_domanda:lang[locale][landing].nome_domanda3,
        domanda:lang[locale][landing].domanda4,
        tipologia:"domanda_checkbox",
        img: require("./img/30.png").default,
        selezioni:[
          lang[locale][landing].corpo1,lang[locale][landing].corpo1,lang[locale][landing].corpo2,lang[locale][landing].corpo3,lang[locale][landing].corpo4,lang[locale][landing].corpo5,lang[locale][landing].corpo6
        ],
        pulsanti:1,
      },
      {
        nome_domanda:lang[locale][landing].nome_domanda4,
        domanda:lang[locale][landing].domanda5,
        sottodomanda:lang[locale][landing].sottodomanda2,
        tipologia:"domanda_checkbox",
        img: require("./img/40.png").default,
        selezioni:[
          lang[locale][landing].obbiettivo1,lang[locale][landing].obbiettivo2,lang[locale][landing].obbiettivo3,lang[locale][landing].obbiettivo4,lang[locale][landing].obbiettivo5
        ],
        pulsanti:1,
      },
      {
        domanda:lang[locale][landing].nome_domanda5,
        img: require("./img/80.png").default,
        tipologia:"selezioni",
        pulsanti:1,
        titoletto:lang[locale][landing].titoletto1,
        selezioni:[
            {
    
              descrizione:lang[locale][landing].eta1,
              selected:false,
            },
            {
              descrizione:lang[locale][landing].eta2,
              selected:false,
            },
            {
              descrizione:lang[locale][landing].eta3,
              selected:false,
            },
            {
              descrizione:lang[locale][landing].eta4,
              selected:false,
            },
          ],
      },
      {
        nome_domanda:lang[locale][landing].nome_domanda6,
        domanda:lang[locale][landing].domanda6,
        tipologia:"domanda_checkbox",
        img: require("./img/100.png").default,
        selezioni:[
            lang[locale][landing].descrizione1,lang[locale][landing].descrizione2,lang[locale][landing].descrizione3,lang[locale][landing].descrizione4,lang[locale][landing].descrizione5,lang[locale][landing].descrizione6
        ],
        pulsanti:1,
      },
      
      {
        titolone:lang[locale][landing].titolone1, 
        domanda:lang[locale][landing].domanda7,
        pulsanti:1,
        tipologia:"messaggio",
      },
      {
        titolo_piccolo:lang[locale][landing].titolo_piccolo, 
        titoletto:lang[locale][landing].titoletto2,
        domanda:lang[locale][landing].domanda8,
        pulsanti:1,
        tipologia:"finale",
        link:context.voluum_domain,
      },
   
  ]
   

    const [step, setStep] = useState(0);
    const [query, setQuery] = useState(window.location.search);
    const [domande, setDomande] = useState(setupDomande);
    const [stic, setStic] = useState(0);

    let history = useHistory();


 
    useEffect(()  => {
        document.body.classList.add('lp3');
       
    });

 


  function renderDomanda(){
    return (
      <div>
        <h1 class="titolo">Web de CITAS</h1>
         <img src={domande[step].img}/>
         {domande[step].titoletto && (<h4>{domande[step].titoletto}</h4>)}
         
       

         <p className="testoDomanda" dangerouslySetInnerHTML={{__html: domande[step].domanda}}></p>
      </div>
      )
  }


  function renderSelezioni(){

    return (<div>
       <h2 class="titolo">{domande[step].domanda}</h2>
    <img src={domande[step].img}/>
      {domande[step].titoletto && (<h2 class="titolettoSelezioni">{domande[step].titoletto}</h2>)}
      {domande[step].sottotitoletto && (<h3 class="sottotitolettoSelezioni">{domande[step].sottotitoletto}</h3>)}
      <div className="row">
      {
        domande[step].selezioni.map((value,index)=>{
          
          return (
          
            <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 itemSelezioni" onClick={() => {setSelected(index)}}>
              {value.img &&(<img src={value.img}/>)}
              <span className={domande[step].selezioni[index].selected ? ("descrizioneSelezioniSelected") : ("descrizioneSelezioni")}>{value.descrizione}</span>
              <span className={domande[step].selezioni[index].selected ? ("checkboxSelected visible") : ("checkboxSelected")}><FontAwesomeIcon icon={faCheckCircle} /></span>
            </div>

            
         
          )}
        )
      }
       </div>
    </div>)
  }

  function renderDomandaCheckbox(){

    return (<div className="domandaCheckbox">
      <h2 class="titolo">{domande[step].nome_domanda}</h2>
      <img src={domande[step].img}/>
      {domande[step].domanda && (<h2 class="titolettoSelezioni">{domande[step].domanda}</h2>)}
      {domande[step].sottodomanda && (<h3 class="sottotitolettoSelezioni">{domande[step].sottodomanda}</h3>)}
      <div className="row">
      <ul>
      {
        domande[step].selezioni.map((value,index)=>{
          return (
                <li><label><input type="checkbox"/><span>{value}</span></label></li>
            )}
        )
      }
        </ul>
       </div>
    </div>)
  }



  function domandaDuePulsanti(){

    return (<div className="domandaCheckbox">
      <h2 class="titolo">{domande[step].nome_domanda}</h2>
      <img src={domande[step].img}/>
      {domande[step].domanda && (<h2 class="titolettoSelezioni">{domande[step].domanda}</h2>)}
      {domande[step].sottodomanda && (<h3 class="sottotitolettoSelezioni">{domande[step].sottodomanda}</h3>)}
      <div className="row">
      <p>{domande[step].testo}</p>
       </div>
    </div>)
  }


  function setSelected(qualeSelezioni){

    var temp = domande;
      temp[step].selezioni.map((item,index) => {
        if(index == qualeSelezioni){
          temp[step].selezioni[index].selected = !temp[step].selezioni[index].selected
        }
      })

      setDomande(temp)
      setStic(prev => prev+1)

  }

  

  function renderStepFinale(){
    return (
      <div className="ultimostep">
           {domande[step].titolone && (<h1 class="titolone">{domande[step].titolone}</h1>)}
           {domande[step].titolo_piccolo && (<h3 class="titolo_piccolo">{domande[step].titolo_piccolo}</h3>)}
           {domande[step].img && (<img src={domande[step].img}/>)}
         {domande[step].titoletto && (<h4 className="ultimostep" dangerouslySetInnerHTML={{__html: domande[step].titoletto}}></h4>)}
         <p  dangerouslySetInnerHTML={{__html: domande[step].domanda}}></p>
      </div>
      )
  }

  function renderMessaggio(){
    return (
      <div>
          {domande[step].titolone && (<h1 class="titolone">{domande[step].titolone}</h1>)}
          {domande[step].titolo_piccolo && (<h3 class="titolo_piccolo" dangerouslySetInnerHTML={{__html: domande[step].titolo_piccolo}}></h3>)}
          {domande[step].titoletto && (<h4 dangerouslySetInnerHTML={{__html: domande[step].titoletto}}></h4>)}
         <p  dangerouslySetInnerHTML={{__html: domande[step].domanda}}></p>
      </div>
      )
  }

  function DomandaBody(){

    if(step == Object.keys(domande).length ){
       window.location.replace('/find'+query);
    }

    if(domande[step].tipologia == "domanda"){
      return renderDomanda()
    }
    else if(domande[step].tipologia == "selezioni"){
      return renderSelezioni()
    }
    else if(domande[step].tipologia == "messaggio"){
      return renderMessaggio()
    }
    else if(domande[step].tipologia == "domanda_checkbox"){
        return renderDomandaCheckbox()
      }
    else if(domande[step].tipologia == "domandaDuePulsanti"){
        return domandaDuePulsanti()
    }
    else if(domande[step].tipologia == "finale"){
      return renderStepFinale()
    }
    
   
  }

  function StampaPulsanti(){
    if(domande[step].pulsanti == 2){
      return (<div className="contieniPulsanti">
        <Button onClick={() => { setStep(prev => prev+1) }}>SI</Button>
        <Button onClick={() => {setStep(prev => prev+1) }} variant="secondary">{lang[locale][landing].pulsante1}</Button> 
      </div>)
    }
    if(domande[step].pulsanti == 1 && domande[step].tipologia == "finale"){
      return (<div className="contieniPulsanti buttonStepFinale">
      <Button href={domande[step].link} className="unico">{lang[locale][landing].pulsante2}</Button>
    </div>)
    }
    if(domande[step].pulsanti == 1 && domande[step].tipologia != "finale"){
      return (<div className="contieniPulsanti">
      <Button onClick={() => { setStep(prev => prev+1) }} className="unico">{lang[locale][landing].pulsante3}</Button>
    </div>)
    }
  }


  useEffect(() => {
    var scripts = [
      { url : "https://a.exoclick.com/tag_gen.js", data: {name: "data-goal",value:"f349300c9a81f952e7c3ced5ca1c5cc2"}, tipo: "script", network:"exo" },
      { url : "//tsyndicate.com/api/v1/retargeting/set/4198d2d7-a5b4-4e70-9a29-a36977f9f4e4", tipo: "image" , network: "trs" },
      { url : "https://ads.traffichunt.com/adv_ret/?adv_pixel_id=916&nid=3", tipo: "image" , network: "trhunt" }
    ]

    var element = null

    for(let i=0;i<scripts.length;i++){
        if(scripts[i].tipo == "script"){
          element = document.createElement('script')
          element.src = scripts[i].url
          if(scripts[i].data){
            element.setAttribute(scripts[i].data.name, scripts[i].data.value)
          }
          element.async = true
        }
        if(scripts[i].tipo == "image"){
          element = document.createElement('img')
          element.src = scripts[i].url
          element.width = "0"
          element.height = "0"
        }
        document.body.appendChild(element);
    }
  },[]); 
        

	return ( <div className="mainStreamGoogle1" style={{height: window.innerHeight}}>
    <Modal
        show={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modale_landing lp3"
        onHide={() => {return } }
      >
        <div className="contenitoreDomande">
        <Modal.Body>
            <div class="lpbody">
              <DomandaBody/>
             
            </div>
        </Modal.Body>
        <Modal.Footer>
           <StampaPulsanti/>
         </Modal.Footer>
         </div>
         <div className="footerDomande">
            <span>Ganko Media Sagl - CHE-308.121.620 © - Centro Galleria 3 - Via cantonale 4 - 6928 Manno - CH</span>
            <ul>
              <li><a href="https://placerbonito.com/legal/privacy">Privacy</a> -</li>
              <li><a href="https://placerbonito.com/legal/terms">Terms and Conditions</a> - </li>
              <li><a href="https://placerbonito.com/legal/cookie">Cookie</a></li>
            </ul>
          </div>
      </Modal>
      <CookieBanner
      message={lang[locale][landing].footer1}
      wholeDomain={true}
      policyLink="https://placerbonito.com/legal/cookie"
     
      acceptButtonText={lang[locale][landing].footer4}
      managePreferencesButtonText={lang[locale][landing].footer3}
      privacyPolicyLinkText={lang[locale][landing].footer2}
      onAcceptPreferences={() => {}}
      onAcceptStatistics={() => {}}
      onAcceptMarketing={() => {}}
    />
   
      </div>);


}