import React, { useState,useEffect,useContext  } from 'react';
import { Route, Switch, BrowserRouter, Redirect} from 'react-router-dom';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import Context from './components/_helpers/context';
import Lp1 from './components/lp/lp1/lp1';
import Lp2 from './components/lp/lp2/lp2';
import Lp3 from './components/lp/lp3/lp3';
import Lp4 from './components/lp/lp4/lp4';
import Lp5 from './components/lp/lp5/lp5';
import Blog from './components/lp/blog1/blog';
import Offer1 from './components/offers/1/offer1';

const config = require("./config.json"); 
const translations = require('./components/_helpers/lang/lang.json')

export default function App() {
 
  const [fingerprint, setFingerprint] = useState();
  getBrowserFingerprint()
  const domain = window.location.hostname;

  const isAdult =  config[domain].isAdult;
  const country = (navigator.language || navigator.userLanguage || navigator.userAgentData).substr(0, 2)
 
  var lang = Object.keys(translations).includes(country) ? country : config[domain].default_language
  lang = config[domain].forced_language ? config[domain].forced_language : lang
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  //------------ settaggio servizio su articolo in caso di blog
  const servizi_validi = ["PlacerBonito","JammyDate","YaTeQuiero","FlirtVicini","jetztabspritzen"]
  var service_on_url = urlParams.get('service') ? urlParams.get('service'):null
  const blog_domain = servizi_validi.includes(service_on_url) ? service_on_url : "JammyDate"

  async function getBrowserFingerprint(){
    const fp = await FingerprintJS.load();
    const result = await fp.get();
  
    const visitorId = result.visitorId;
  
    setFingerprint(visitorId)
  }

  const props = {
    isAdult:isAdult,
    lang:lang,
    default_language: config[domain].forced_language ? config[domain].forced_language : config[domain].default_language,
    voluum_domain:config[domain].voluum_domain,
    blog_domain:blog_domain,
    logo:config[domain].logo,
    remoteLogo:config[domain].remoteLogo,
    title:config[domain].title,
    fingerprint : fingerprint,
    terms:config[domain].terms,
    cities_file:config[domain].cities_file,
    user_form:{}
  }



  return (
      
    <BrowserRouter>
      <Context.Provider value={props}>
      <Switch>
								<Route path="/lp/lp1" >
                  {isAdult ? (<Lp1 {...props}/>):( <Redirect to="/blog" />)}
                </Route>
								
                <Route path="/lp/lp2" >
                  {isAdult ? (<Lp2 {...props}/>):(<Redirect to="/blog" />)}
                </Route>
                
                <Route path="/lp/lp3" >
                  <div className="lp3">
                     <Lp3/>
                   </div>
                </Route>
               
                <Route path="/lp/lp4" >
                  <div className="lp4">
                     <Lp4/>
                   </div>
                </Route>
               
                <Route path="/lp/lp5" >
                  <div className="lp5">
                     <Lp5/>
                   </div>
                </Route>

                <Route path="/offers/1" >
                  <div className="offer1">
                     <Offer1/>
                   </div>
                </Route>

                <Route path="/blog/:topicId/:cat">
                    <Blog {...props} className="lp3"/>
                </Route>

                <Route path="/blog">
                    <Blog {...{isAdult:isAdult,lang:lang,default_language:config[domain].default_language}} className="lp3"/>
                </Route>

                <Route path="/*">
                  <Redirect to="/blog" />
                </Route>

               
      </Switch>
      </Context.Provider>
    </BrowserRouter>
  )
}


