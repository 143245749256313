import React, { useEffect,useContext,useState } from "react";
import { Route, Switch} from 'react-router-dom';

import Menu_ES from "./components/ES/menu";
import Content_ES from "./components/ES/content";
import Comments_ES from "./components/ES/comments";
import Footer_ES from "./components/ES/footer";
import AboutUs_ES from "./components/ES/about";

import Menu_IT from "./components/IT/menu";
import Content_IT from "./components/IT/content";
import Comments_IT from "./components/IT/comments";
import Footer_IT from "./components/IT/footer";
import AboutUs_IT from "./components/IT/about";

import Menu_De from "./components/DE/menu";
import Content_DE from "./components/DE/content";
import Comments_DE from "./components/DE/comments";
import Footer_DE from "./components/DE/footer";
import AboutUs_DE from "./components/DE/about";

import Category from "./components/category";

import Home from "./components/home";

import Terms from "./components/terms";
import Privacy from "./components/privacy";

import Context from '../../_helpers/context';



import './css/styles.css'
import './css/vendor.css'

export default function Blog() {
  
  const context = useContext(Context)
  const locale = context.lang


  switch(locale) {
    case 'es':
          return (<>
                <Menu_ES/>
                <Route path="/blog/category/:cat" >
                  <Category/>
                </Route>

                <Route exact path="/blog/home" >
                    <>
                      <Home/>
                    </>
                </Route>
                
                <Route exact path="/blog" >
                    <>
                      <Content_ES/>
                      <Comments_ES/> 
                    </>
                </Route>
          
                <Route exact path="/blog/about" >
                    <>
                      <AboutUs_ES/>
                    </>
                </Route>
          
          
                <Route exact path="/blog/terms" >
                    <>
                      <Terms/>
                    </>
                </Route>
                <Route exact path="/blog/privacy" >
                    <>
                      <Privacy/>
                    </>
                </Route>
                
                
          
                <Footer_ES/>
                </>);
    case 'it':
      return(<>
           <Menu_IT/>
           <Route path="/blog/category/:cat" >
             <Category/>
           </Route>


           <Route exact path="/blog/home" >
               <>
                 <Home/>
               </>
           </Route>
          
           <Route exact path="/blog" >
               <>
                 <Content_IT/>
                 <Comments_IT/> 
               </>
           </Route>
     
           <Route exact path="/blog/about" >
               <>
                 <AboutUs_IT/>
               </>
           </Route>
     
     
           <Route exact path="/blog/terms" >
               <>
                 <Terms/>
               </>
           </Route>
           <Route exact path="/blog/privacy" >
               <>
                 <Privacy/>
               </>
           </Route>
           
          
     
           <Footer_IT/>
           </>
     );
    case 'de':
      return(<>
           <Menu_De/>
           <Route path="/blog/category/:cat" >
             <Category/>
           </Route>


           <Route exact path="/blog/home" >
               <>
                 <Home/>
               </>
           </Route>
          
           <Route exact path="/blog" >
               <>
                 <Content_DE/>
                 <Comments_DE/> 
               </>
           </Route>
     
           <Route exact path="/blog/about" >
               <>
                 <AboutUs_DE/>
               </>
           </Route>
     
     
           <Route exact path="/blog/terms" >
               <>
                 <Terms/>
               </>
           </Route>
           <Route exact path="/blog/privacy" >
               <>
                 <Privacy/>
               </>
           </Route>
           
          
     
           <Footer_DE/>
           </>
     );
     default:
        return (<>
          <Menu_IT/>
          <Route path="/blog/category/:cat" >
            <Category/>
          </Route>


          <Route exact path="/blog/home" >
              <>
                <Home/>
              </>
          </Route>
         
          <Route exact path="/blog" >
              <>
                <Content_IT/>
                <Comments_IT/> 
              </>
          </Route>
    
          <Route exact path="/blog/about" >
              <>
                <AboutUs_IT/>
              </>
          </Route>
    
    
          <Route exact path="/blog/terms" >
              <>
                <Terms/>
              </>
          </Route>
          <Route exact path="/blog/privacy" >
              <>
                <Privacy/>
              </>
          </Route>
          
         
    
          <Footer_IT/>
          </>);
  }

}